import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"

// Components
import Layout from "../components/layout"
import Hero from "../components/hero"
import { Seo } from "../components/seo"

// Loadable Components
const Gallery = loadable(() => import("../components/gallery"))
const Highlight = loadable(() => import("../components/highlight"))

const Rooms = ({ data }) => {
  const rooms = data.contentfulPage

  return (
    <Layout>
      <Hero
        type={rooms.pageType}
        title={rooms.pageTitle.split("\\n")}
        image={rooms.heroImage}
        shortcuts={rooms.shortcuts}
      />
      {rooms.pageContent.map((content, i) => {
        if (content.internal.type === "ContentfulGallery") {
          return (
            <Gallery
              key={i}
              id={content.hrefId}
              title={content.title}
              description={content.description.description}
              images={content.images}
            />
          )
        } else if (content.internal.type === "ContentfulHighlight") {
          return (
            <Highlight
              key={i}
              caption={content.content}
              title={content.title}
              cta={content.cta}
              image={content.image}
              location={content.location}
              link={content.link}
              isEven={content.isEven}
              isReverse={content.isReverse}
            />
          )
        } else {
          return null
        }
      })}
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulPage(pageName: { eq: "Rooms" }) {
      pageType
      pageTitle
      heroImage {
        title
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 50)
      }
      shortcuts {
        title
        links {
          href
          title
        }
      }
      pageContent {
        ... on ContentfulHighlight {
          internal {
            type
          }
          title
          cta
          content {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                description
                gatsbyImageData(height: 300)
              }
            }
          }
          image {
            description
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          location {
            lat
            lon
          }
          link
          isEven
          isReverse
        }
        ... on ContentfulGallery {
          internal {
            type
          }
          title
          description {
            description
          }
          images {
            format
            position
            type
            image {
              gatsbyImageData(
                placeholder: BLURRED
                layout: FULL_WIDTH
                quality: 50
              )
              description
            }
            rowName
          }
          hrefId
        }
      }
    }
  }
`

export default Rooms

export const Head = () => <Seo title="B&B S. Stae | Rooms" />
